<template>
	<v-card max-width="800" class="mx-auto mt-5" rounded="xl">
		<v-card-title>{{ $t('settings.emailNotificationsPreference') }}</v-card-title>
		<v-card-text>
			<p>{{ $t('emails.consentReceiveEmails') }}</p>
			<v-form ref="form" v-model="valid">
				<template>
					<p class="text-h5 mt-4 mb-1">General</p>
					<v-switch v-model="newsletter" label="Newsletter" />
					<v-switch v-model="promotional" :label="$t('emails.promotional')" />
				</template>
				<template>
					<p class="text-h5 mt-4 mb-1">OverSOS Network</p>
					<v-switch v-model="changesApproved" :label="$t('emails.changesApproved')" />
					<v-switch v-model="changesRefused" :label="$t('emails.changesRefused')" />
				</template>
			</v-form>
		</v-card-text>

		<v-card-actions>
			<v-btn color="primary" rounded @click="callChangeNotifications()">
				{{ $t('settings.update') }}
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'SettingsChangeNotificationsForm',
	data() {
		return {
			valid: false,
			promotional: null,
			newsletter: null,
			changesApproved: null,
			changesRefused: null
		}
	},
	computed: {
		...mapGetters({
			currentUser: 'user/currentUser'
		})
	},
	created() {
		this.promotional = this.currentUser.permissions.emails.promotional
		this.newsletter = this.currentUser.permissions.emails.newsletter
		this.changesApproved = this.currentUser.permissions.emails.changesApproved
		this.changesRefused = this.currentUser.permissions.emails.changesRefused
	},
	watch: {
		currentUser(val) {
			if (val) {
				this.promotional = this.currentUser.permissions.emails.promotional
				this.newsletter = this.currentUser.permissions.emails.newsletter
				this.changesApproved = this.currentUser.permissions.emails.changesApproved
				this.changesRefused = this.currentUser.permissions.emails.changesRefused
			}
		}
	},
	methods: {
		callChangeNotifications() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.changeNotifications({
				promotional: this.promotional,
				newsletter: this.newsletter,
				changesApproved: this.changesApproved,
				changesRefused: this.changesRefused
			})
		},
		...mapActions('settings', ['changeNotifications'])
	}
}
</script>
